import { GET_USER_ORGANIZATIONS, GET_USER_ORGANIZATION,GET_ORG_PENDING_INVITES } from '../types';

const initialState = {
  loading: false,
  data: {},
};

export default class Organizations {
  static userOrganizations(state = initialState, action) {
    switch (action.type) {
      case `${GET_USER_ORGANIZATIONS}_PENDING`:
        return { ...state, loading: true };
      case `${GET_USER_ORGANIZATIONS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_USER_ORGANIZATIONS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static userOrganization(state = initialState, action) {
    switch (action.type) {
      case `${GET_USER_ORGANIZATION}_PENDING`:
        return { ...state, loading: true };
      case `${GET_USER_ORGANIZATION}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_USER_ORGANIZATION}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static orgPendingInvites(state = initialState, action) {
    switch (action.type) {
      case `${GET_ORG_PENDING_INVITES}_PENDING`:
        return { ...state, loading: true };
      case `${GET_ORG_PENDING_INVITES}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_ORG_PENDING_INVITES}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
