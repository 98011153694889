import {
  FETCH_GROUPED_CONTESTANTS,
  FETCH_GROUPED_CONTESTANTS_SUMMARY,
  FETCH_REPORT,
} from "../types";

const initialState = {
  loading: false,
  error: null,
  data: [],
};

export const dashboardDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_GROUPED_CONTESTANTS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_GROUPED_CONTESTANTS}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload.data ? action.payload.data : [],
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${FETCH_GROUPED_CONTESTANTS}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const contestantsDataSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_GROUPED_CONTESTANTS_SUMMARY}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_GROUPED_CONTESTANTS_SUMMARY}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload.data ? action.payload.data : [],
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${FETCH_GROUPED_CONTESTANTS_SUMMARY}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const reportDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_REPORT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_REPORT}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload.data ? action.payload.data : [],
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${FETCH_REPORT}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};
