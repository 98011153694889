import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default (props)=> {
  const {
    children,
    tabs,
  } = props;
  
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
          { tabs }
      </AppBar>
      { children } 
    </div>
  );
}
