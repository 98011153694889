export const formatChartData = (data, dataFor) => {
  let labels = [],
    datasets = [];

  const config = {
    backgroundColor: "#22CECE",
    borderWidth: 1,
  };

  const config2 = {
    backgroundColor: "#FF3D67",
    borderWidth: 1,
  };

  const config3 = {
    backgroundColor: "#36A2EB",
    borderWidth: 1,
  };

  switch (dataFor) {
    case "contestants": {
      labels = data.candidatesSummary
        ? data.candidatesSummary.map(
            (item) => `${item.FirstName} ${item.LastName}`
          )
        : [];
      datasets = [
        {
          label: `${"Votes"}`,
          data: data.candidatesSummary
            ? data.candidatesSummary.map((item) => item.Votes)
            : [],
          ...config,
        },
      ];
      break;
    }

    case "contestantsRevenue": {
      labels = data.candidatesSummary
        ? data.candidatesSummary.map(
            (item) => `${item.FirstName} ${item.LastName}`
          )
        : [];
      datasets = [
        {
          label: `${"Revenues $"}`,
          data: data.candidatesSummary
            ? data.candidatesSummary.map((item) => item.Revenue)
            : [],
          ...config3,
        },
      ];

      break;
    }

    case "dates": {
      labels = data.datesSummary
        ? data.datesSummary.map((item) => item.Date || "2021-01-01")
        : [];
      datasets = data.datesSummary
        ? [
            {
              label: `${"Votes"}`,
              data: data.datesSummary.map((item) => item.Votes),
              ...config,
            },
          ]
        : [];
      break;
    }

    case "datesRevenue": {
      labels = data.datesSummary
        ? data.datesSummary.map((item) => item.Date || "2021-01-01")
        : [];
      datasets = [
        {
          label: `${"Revenues"}`,
          data: data.datesSummary
            ? data.datesSummary.map((item) => item.Revenue)
            : [],
          ...config3,
        },
      ];

      break;
    }

    default: {
      labels = data.candidatesSummary
        ? data.candidatesSummary.map(
            (item) => `${item.FirstName} ${item.LastName}`
          )
        : [];
      datasets = [
        {
          label: `${"Votes"}`,
          data: data.candidatesSummary
            ? data.candidatesSummary.map((item) => item.Votes)
            : [],
          ...config,
        },
      ];
      break;
    }
  }

  return {
    labels,
    datasets,
  };
};

export const formatContestantsData = (contestant) => ({
  id: contestant.Id,
  name: `${contestant.FirstName} ${contestant.LastName}`,
  photo: contestant.Photo,
  votes: contestant.Votes,
});
