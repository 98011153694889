import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Select from "react-select";

const style = {
  selectFormControl: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    margin: "30px 0",
  },

  inputHelperTextError: {
    color: "#f44336",
    padding: "3px 10px",
  },
};

// to lean more about react-select you may use : https://www.npmjs.com/package/react-select and https://react-select.com
class SimpleSelect extends React.Component {
  state = {
    selectedOption: null,
    inputValue: "",
  };

  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption);
    this.setState({ selectedOption });
  };

  handleInputChange = () => {
    this.setState({ inputValue: event.target.value });
    this.props.onInputChange(event.target.value);
  };

  render() {
    const { classes, value, options, loading, searching, error } = this.props;

    return (
      <div className={classes.selectFormControl}>
        <Select
          isSearchable
          name="simple-select"
          placeholder={value}
          value={value}
          onChange={this.handleChange}
          options={options}
          onInputChange={this.handleInputChange}
          isLoading={searching || loading}
        />
        {error && (
          <Typography
            variant="caption"
            className={classes.inputHelperTextError}
          >
            {error}
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(style)(SimpleSelect);
