import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserAccountComponent from '../../components/accounts/UserAccount';
import { accountAction } from '../../../redux/accounts/index';
import {
  GET_USER_ORGANIZATION,
  GET_APP_INFO,
  GET_ORG_PENDING_INVITES,
} from '../../../redux/accounts/types';
import identityService from '../../../services/identityService';
import Toast from '../../../components/MessageToast';
import env from '../../../config/environment/index';
import IsLoading from '../../../components/isLoading';

export default () => {
  const dispatch = useDispatch();
  const { claims } = JSON.parse(localStorage.getItem('au'));
  const orgId = claims.userOrg ? claims.userOrg.orgId : null;
  useEffect(() => {
    dispatch(
      accountAction({
        method: 'GET',
        path: `/api/users/orgs/${orgId}`,
        actionType: GET_USER_ORGANIZATION,
      })
    );
    dispatch(
      accountAction({
        method: 'GET',
        path: `/api/apps/${env.APP_ID}`,
        actionType: GET_APP_INFO,
      })
    );
    dispatch(
      accountAction({
        method: 'GET',
        path: `/api/orgs/${orgId}/invites`,
        actionType: GET_ORG_PENDING_INVITES,
      })
    );
  }, []);

  const onOrgCreate = async (orgName) => {
    const response = await identityService({
      method: 'POST',
      path: '/api/orgs',
      data: {
        name: orgName,
      },
    });
    Toast({
      message: response.message,
      type: response.error ? 'error' : 'success',
      duration: 5000,
      onClose: () => {
        dispatch(
          accountAction({
            actionType: GET_USER_ORGANIZATIONS,
            path: '/api/users/orgs',
            method: 'GET',
          })
        );
      },
    });
  };

  const addUserToOrg = async (email, role) => {
    const response = await identityService({
      method: 'POST',
      path: '/api/users/invite',
      data: {
        claims: {
          organization: {
            orgId,
            role,
          },
        },
        invfor: 'org',
        email,
        appDomain: `${window.location.protocol}//${window.location.host}`,
      },
    });

    if (response.error) {
      Toast({
        message: response.message,
        type: 'error',
        duration: 5000,
      });
    } else {
      Toast({
        message: response.message,
        type: 'success',
        duration: 5000,
      });
    }
  };

  const onChangeUserRole = async (uid, role) => {
    const response = await identityService({
      method: 'PATCH',
      path: `/api/orgs/${orgId}/users/${uid}`,
      data: {
        role,
      },
    });

    if (response.error) {
      Toast({
        message: response.message,
        type: 'error',
        duration: 5000,
      });
    } else {
      Toast({
        message: response.message,
        type: 'success',
        duration: 3000,
        onClose: () => {
          dispatch(
            accountAction({
              method: 'GET',
              path: `/api/users/orgs/${orgId}`,
              actionType: GET_USER_ORGANIZATION,
            })
          );
        },
      });
    }
  };

  const onRemoveUser = async (uid) => {
    const response = await identityService({
      method: 'DELETE',
      path: `/api/orgs/${orgId}/users/${uid}`,
    });

    if (response.error) {
      Toast({
        message: response.message,
        type: 'error',
        duration: 5000,
      });
    } else {
      Toast({
        message: response.message,
        type: 'success',
        duration: 3000,
        onClose: () => {
          dispatch(
            accountAction({
              method: 'GET',
              path: `/api/users/orgs/${orgId}`,
              actionType: GET_USER_ORGANIZATION,
            })
          );
        },
      });
    }
  };

  const app = useSelector((store) => store.specificApp);
  const orgInvites = useSelector((store) => store.orgPendingInvites);
  const org = useSelector((store) => store.userOrg);

  return org.loading ? (
    <IsLoading />
  ) : (
    <UserAccountComponent
      org={org.data.results || {}}
      loading={org.loading}
      onOrgCreate={onOrgCreate}
      app={app.data.results || {}}
      orgInvites={orgInvites.data.results || []}
      onAddUser={addUserToOrg}
      onRemoveUser={onRemoveUser}
      onChangeUserRole={onChangeUserRole}
    />
  );
};
