import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UsersListComponent from "../../components/List/UsersListComponent";
import Loading from "../../../components/isLoading";
import { fetchContestantsDataSummary } from "../../../redux/dashboard/index";

const formatContestants = (data) =>
  data.map((elt) => ({
    id: elt.Id,
    name: `${elt.FirstName} ${elt.LastName}`,
    picture: elt.Photo,
    email: elt.Email,
    category: elt.Category,
    revenue: elt.Revenue,
    votes: elt.Votes,
  }));

export class Contestants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contestants: [],
      searchTerm: "",
      pagination: {
        page: 1,
        startIndex: 0,
        endIndex: 25,
        count: 0,
      },
    };
  }

  componentDidMount() {
    if (!this.props.summaryData.results) {
      this.props.getContestants();
    } else {
      const { summaryData } = this.props;
      const contestantsData = summaryData.data.results
        ? formatContestants(
            summaryData.data.results.LatestOverallVotes.CandidatesSummary
          )
        : [];

      this.setState({
        ...this.state,
        contestants: contestantsData.slice(
          this.state.pagination.startIndex,
          this.state.pagination.endIndex
        ),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { summaryData } = this.props;
    const formattedData = summaryData.data.results
      ? formatContestants(
          summaryData.data.results.LatestOverallVotes.CandidatesSummary
        )
      : [];

    let contestantsData =
      formattedData[0] &&
      formattedData.filter((elt) => elt.name.includes(this.state.searchTerm));

    if (
      JSON.stringify(prevProps.summaryData.data) !==
      JSON.stringify(summaryData.data)
    ) {
      this.setState({
        ...this.state,
        contestants: contestantsData.slice(
          this.state.pagination.startIndex,
          this.state.pagination.endIndex
        ),
        pagination: {
          page: prevState.pagination.page,
          startIndex: prevState.pagination.startIndex,
          endIndex: prevState.pagination.endIndex,
          count: contestantsData.length,
        },
      });
    }

    if (
      prevState.pagination.page !== this.state.pagination.page ||
      prevState.searchTerm !== this.state.searchTerm
    ) {
      const { page, startIndex, endIndex } = this.state.pagination;

      this.setState({
        ...this.state,
        contestants: contestantsData.slice(startIndex, endIndex),
        pagination: {
          page: this.state.searchTerm === "" ? this.state.pagination.page : 1,
          startIndex:
            this.state.searchTerm === "" ? this.state.pagination.startIndex : 0,
          endIndex:
            this.state.searchTerm === "" ? this.state.pagination.endIndex : 25,
          count: contestantsData.length,
        },
      });
    }
  }

  render() {
    const { summaryData } = this.props;
    console.log(">>>>>>>>>", summaryData);

    const crumps = [
      {
        title: "Contestants",
        path: "/contestants",
      },
    ];

    const onPaginationChange = (event, page) => {
      this.setState((prevState) => {
        return {
          ...prevState,
          pagination: {
            page,
            startIndex:
              page > prevState.pagination.page
                ? prevState.pagination.startIndex +
                  25 * (page - prevState.pagination.page)
                : prevState.pagination.startIndex -
                  25 * (prevState.pagination.page - page),
            endIndex:
              page > prevState.pagination.page
                ? prevState.pagination.endIndex +
                  25 * (page - prevState.pagination.page)
                : prevState.pagination.endIndex -
                  25 * (prevState.pagination.page - page),
            count:
              summaryData.data.results &&
              summaryData.data.results.LatestOverallVotes.CandidatesSummary
                .length,
          },
        };
      });
    };

    const searchAuthor = (term) => {
      this.setState({ ...this.state, searchTerm: term });
    };

    return summaryData.loading ? (
      <Loading />
    ) : (
      <div>
        <UsersListComponent
          content={this.state.contestants}
          crumps={crumps}
          count={this.state.pagination.count}
          page={this.state.pagination.page}
          onPaginationChange={onPaginationChange}
          onSearching={searchAuthor}
          searchValue={this.state.searchTerm}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  summaryData: state.contestantsDataSummary,
});

const mapDispatchToProps = (dispatch) => ({
  getContestants: () => dispatch(fetchContestantsDataSummary()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Contestants));
