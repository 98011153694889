import env from './environment/index';

import axios from 'axios';

const httpInstance = axios.create({
  baseURL: `${env.IDENTITY_API}`,
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

httpInstance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('au'));
    if (user) {
      config.headers.authorization = user.atkn;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default httpInstance;
