import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Button
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import 'date-fns';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import {
  primaryColor,
  infoColor
} from "../../../assets/styles/base";

const DateRangePicker = ({onDateAccept,isDatePicker,onCancel}) => {
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });
  
 const [selectedDay, setSelectedDay] = useState(null);
  
 const onClose = () => onCancel()
 
  const onChange = () =>{
  if (isDatePicker) {
    const date = `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`
    
    onDateAccept(date);
  } else {
    const {from, to} = selectedDayRange;
    const startDate = `${from.year}-${from.month}-${from.day}`
    const endDate = `${to.year}-${to.month}-${to.day}`;
    
    onDateAccept(startDate, endDate);
  }
  }
    
  return (
    <Calendar
      value={isDatePicker?selectedDay:selectedDayRange}
      onChange={isDatePicker? setSelectedDay:setSelectedDayRange}
      shouldHighlightWeekends
      colorPrimary={primaryColor[0]}
      colorPrimaryLight = '#F8F8F8'
      maximumDate={utils().getToday()}
      renderFooter={()=> (
       <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem 2rem' }}>
           <Button
            onClick={onChange}
            autoFocus
            style={{ background: primaryColor[0], marginRight:10 }}
            variant='contained'
            disabled={isDatePicker?!selectedDay:!selectedDayRange.from || !selectedDayRange.to}
            >
            Ok
          </Button>
          
          <Button
            onClick={onClose}
            style={{ background: infoColor[0] }}
            variant='contained'
            >
            cancel
          </Button>
       </div>
      )}
    />
  );
};

export default ({
  startDate,
  endDate,
  onDateChange,
  onRangeChange,
  lastSyncTime,
  currentDate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    pickerOpen: false,
    rangeOpen: false,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSpecClick = (event) => {
    setAnchorEl(null);
    setState({
      ...state,
      pickerOpen: true,
      openTo: event.target.dataset.range === 'year' ? 'year' : undefined,
      [event.target.dataset.range]: !state[event.target.dataset.range],
    });
  };
  const onDateAccept = (date) => {
    setState({ pickerOpen: false, date: date });
    onDateChange({ date: date, ...state });
    setAnchorEl(null);
  };
  const onDateCancel = () => {
    setState({
      pickerOpen: false,
      rangeOpen: false,
    });
    setAnchorEl(null);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    if (event.target.dataset.range) onRangeChange(event.target.dataset.range);
  };
  
  const handleDateRangeClick = (event) => {
    setAnchorEl(null);
    setState({
      ...state,
      rangeOpen: true,
      [event.target.dataset.range]: !state[event.target.dataset.range],
    });
  };
  
  const onDateRangeAccept = (startDate, endDate) => {
    setState({
      ...state,
      rangeOpen: false,
      date: {startDate, endDate}
    });
    
    onDateChange({ date: { startDate, endDate }, ...state });
    setAnchorEl(null);
  };
  
  return (
    <div>
      <Grid container justify='flex-end'>
      <Typography 
        variant='body1'
        style={{ marginTop: 15}}
      >
        {startDate} to  {endDate}
      </Typography>
        <IconButton
          aria-haspopup='true'
          onClick={handleClick}
          aria-controls='main-menu'
        >
          <Tooltip title='Pick the time range' arrow>
            <DateRangeIcon   />
          </Tooltip>
        </IconButton>
          
        <Menu
          id='main-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
        { 
        // (currentDate && currentDate !== startDate ) &&
          <MenuItem onClick={handleClose} data-range='today'>
            Today
          </MenuItem> 
          }
          <MenuItem onClick={handleClose} data-range='daily'>
            Past Day
          </MenuItem>
          <MenuItem onClick={handleClose} data-range='weekly'>
            Past Week
          </MenuItem>
          <MenuItem onClick={handleClose} data-range='monthly'>
            Past Month
          </MenuItem>
          <MenuItem onClick={handleClose} data-range='yearly'>
            Past Year
          </MenuItem>
          <MenuItem onClick={handleSpecClick} data-range='specDate'>
            Specific Date
          </MenuItem>
          <MenuItem onClick={handleDateRangeClick} data-range='dateRange'>
            Specific Date Range
          </MenuItem>
          
          {/* <DatePicker
            label='Pick the month'
            open={state.pickerOpen}
            onAccept={onDateAccept}
            onCancel={onDateCancel}
            openTo={state.openTo}
          /> */}
          

        </Menu>
        { state.pickerOpen && 
              <span style={{ position: 'absolute'}}>
                <DateRangePicker 
                  isDatePicker 
                  onDateAccept={onDateAccept} 
                  onCancel={onDateCancel} 
                />
              </span>
          }
              { state.rangeOpen && 
                  <span style={{ position: 'absolute'}}>
                    <DateRangePicker 
                      onDateAccept={onDateRangeAccept}
                      onCancel={onDateCancel} 
                    />
                  </span>
              }
        
      </Grid>
      <Grid container justify='flex-start'>
        <Typography 
          variant = 'caption'
          color = "textSecondary"
        >
          Last update at {lastSyncTime}
        </Typography>
      </Grid>
    </div>
  );
};
