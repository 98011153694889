import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { primaryColor } from '../../assets/styles/base';

export default ({
  title,
  content,
  okText,
  open,
  fullWidth,
  fullScreen,
  maxWidth,
  okColor,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth || 'xs'}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          color='secondary'
          variant='contained'
          onClick={onConfirm}
          style={{ backgroundColor: primaryColor[0] }}
        >
          {okText || 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
