import { Grid, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { successColor } from '../../../assets/styles/base';

export default ({ data, background, title }) => {

  return (
    <div>
      <Grid
        container
        justify='center'
        style={{
          background: background || successColor[0],
          padding: 10,
          margin: '10px 0',
        }}
      >
        <Typography>{title}</Typography>
        <Bar
          data={data}
          options={{
            maintainAspectRatio: true,
            animation: true,
            hover: true,
            responsive: true,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    fontColor: '#2b2929',
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    fontColor: '#2b2929',
                  },
                },
              ],
            },
          }}
        />
      </Grid>
    </div>
  );
};
