import React from 'react';
import Layout from '../../layouts/DashboardLayout';
import ReportContainer from '../containers/report/ReportContainer';

export default () => {

  return  <Layout>
            <ReportContainer />
          </Layout>
}

