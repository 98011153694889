import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/isLoading";
import ContestantsTableContainer from "./ContestantsTableContainer";
import ContestantsChart from "./ContestantsChart";
import RecordsContainer from "./RecordsContainer";
import { fetchContestantsDataSummary } from "../../../redux/dashboard/index";
import TimePicker from "../../components/date/TimePickerComponent";
import moment from "moment";
import { formatContestantsData } from "../../../helpers/formatData";

export default () => {
  const summaryData = useSelector((state) => state.contestantsDataSummary);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });
  const [dataState, setDataState] = useState({
    contestantsData: [],
    authorRecords: [],
  });
  const userString = localStorage.getItem("au");
  const user = JSON.parse(userString) || {};
  const userInfo = {
    email: user.email,
    role: user.claims.userOrg.role,
  };

  let startDate = moment(
    state.date && state.date.startDate ? state.date.startDate : state.date
  ).format("YYYY-MM-DD");
  let endDate = moment(
    state.date && state.date.endDate ? state.date.endDate : state.date
  ).format("YYYY-MM-DD");

  if (state.today) {
    startDate = moment().format("YYYY-MM-DD");
    endDate = moment().format("YYYY-MM-DD");
  } else if (state.daily) {
    startDate = moment(state.date).subtract(1, "d").format("YYYY-MM-DD");
    endDate = moment(state.date).subtract(1, "d").format("YYYY-MM-DD");
  } else if (state.weekly) {
    startDate = moment(state.date).subtract(7, "d").format("YYYY-MM-DD");
  } else if (state.monthly) {
    startDate = moment(state.date).subtract(1, "M").format("YYYY-MM-DD");
  } else if (state.yearly) {
    startDate = moment(state.date).subtract(1, "y").format("YYYY-MM-DD");
  }
  useEffect(() => {
    if (
      !summaryData.data[0] ||
      (state.date && state.date.startDate) ||
      state.date !== moment().format("YYYY-MM-DD") ||
      state.daily ||
      state.weekly ||
      state.monthly ||
      state.yearly
    ) {
      dispatch(fetchContestantsDataSummary(startDate, endDate));
    }
  }, [state]);

  let contestantsData = [];

  useEffect(() => {
    const candidatesSummary = summaryData.data.results
      ? summaryData.data.results.LatestOverallVotes.CandidatesSummary
      : [];

    for (const contestant of candidatesSummary) {
      contestantsData.push(formatContestantsData(contestant));
    }

    setDataState({
      ...dataState,
      contestantsData,
    });
  }, [summaryData.data]);

  const onRangeChange = (range) => setState({ [range]: !state[range] });
  const onDateChange = (date) => setState({ ...date });

  const changeContestantsForSelectedMenu = (menu, selectedItems) => {
    const selectedItemsContestants = [];
    if (!selectedItems || selectedItems.length < 1) {
      setDataState({
        ...dataState,
        contestantsData: summaryData.data.results
          ? summaryData.data.results.LatestOverallVotes.CandidatesSummary.map(
              (el) => formatContestantsData(el)
            )
          : [],
      });
      return;
    }
    if (menu === "contestants") {
      const candidatesSummary = summaryData.data.results
        ? summaryData.data.results.LatestOverallVotes.CandidatesSummary
        : [];

      for (const contestant of candidatesSummary) {
        if (selectedItems.findIndex((i) => i.label === contestant.name) > -1) {
          selectedItemsContestants.push(formatContestantsData(contestant));
        }
      }
    }
    if (menu === "dates") {
      const datesSummary = summaryData.data.results
        ? summaryData.data.results.DailyVotes
        : [];

      for (const dateElr of datesSummary) {
        if (dateElr.Date !== "") {
          const date = new Date(dateElr.Date).toISOString().substring(0, 10);
          // if (selectedItems.findIndex((i) => i.label === date) > -1) {
          //   selectedItemsContestants.push(
          //     formatContestantsData(contestant)
          //   );
          // }
        }
      }
    }
    if (selectedItemsContestants.length > 0)
      setDataState({
        ...dataState,
        contestantsData: selectedItemsContestants,
      });
  };

  return (
    <div>
      {summaryData.loading && !summaryData.data.results ? (
        <Loading />
      ) : (
        <>
          <TimePicker
            startDate={startDate}
            endDate={endDate}
            currentDate={moment().format("YYYY-MM-DD")}
            onRangeChange={onRangeChange}
            onDateChange={onDateChange}
            lastSyncTime={moment().format("LLL") || "00:00 AM"}
          />
          <RecordsContainer
            role={userInfo.role}
            isUpdating={summaryData.loading && summaryData.data.results}
            recordsToDisplay={summaryData.data.results || {}}
          />

          <ContestantsChart
            chartData={
              summaryData.data.results
                ? {
                    candidatesSummary:
                      summaryData.data.results.LatestOverallVotes.CandidatesSummary.sort(
                        (a, b) => b.Votes - a.Votes
                      ),

                    datesSummary: summaryData.data.results.DailyVotes.sort(
                      (a, b) => new Date(a.Date) - new Date(b.Date)
                    ),
                  }
                : {}
            }
            role={userInfo.role}
            isUpdating={summaryData.loading && summaryData.data.results}
            isDateRange={startDate !== endDate}
            changeContestantsForSelectedMenu={changeContestantsForSelectedMenu}
          />

          <ContestantsTableContainer
            role={userInfo.role}
            isUpdating={summaryData.loading && summaryData.data[0]}
            contestantsData={dataState.contestantsData}
          />
        </>
      )}
    </div>
  );
};
