import React from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons

// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardIcon from "../../../components/Card/CardIcon.js";
import Loading from "../../../components/isLoading.js";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "../../../assets/styles/views/dashboardStyle.js";
import { Typography } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

const useStyles = makeStyles(styles);

export default ({ records, isUpdating, role, isCurrentUser }) => {
  const classes = useStyles();

  return records.loading ? (
    <GridContainer justify="center">
      <Loading />
    </GridContainer>
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <GroupIcon />
            </CardIcon>
            <div className={classes.cardCategory}>
              <Typography variant="body1">Contestants</Typography>
            </div>
            <br></br>
            <h3 className={classes.cardTitle} style={{ textAlign: "right" }}>
              {isUpdating ? (
                <Skeleton variant="rect" className={classes.skeleton} />
              ) : (
                `${records.contestants || 0}`
              )}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <ThumbUpIcon />
            </CardIcon>
            <div className={classes.cardCategory}>
              <Typography variant="body1">Total Votes</Typography>
            </div>
            <br></br>
            <h3 className={classes.cardTitle} style={{ textAlign: "right" }}>
              {isUpdating ? (
                <Skeleton variant="rect" className={classes.skeleton} />
              ) : (
                `${records.votes || 0}`
              )}
            </h3>
          </CardHeader>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <MonetizationOnIcon />
            </CardIcon>
            <div className={classes.cardCategory}>
              <Typography variant="body1">Revenues</Typography>
            </div>
            <br></br>
            <h4 className={classes.cardTitle} style={{ textAlign: "right" }}>
              {isUpdating ? (
                <Skeleton variant="rect" className={classes.skeleton} />
              ) : (
                `Usd: ${records.totalUsdRevenues || 0} | Rwf: ${
                  records.totalRevenues || 0
                }`
              )}
            </h4>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
