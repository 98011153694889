import searchService from "../../../services/fullUrlService";
import { CLEAR_SEARCH } from "../types";

export const serverSearch = ({ path, term, actionType }) => {
  return async (dispatch) =>
    await dispatch({
      type: actionType,
      message: "All search results",
      payload: searchService(path, term),
    });
};

export const clearSearch = () => async (dispatch) => {
  dispatch({ type: CLEAR_SEARCH });
};
