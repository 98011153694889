import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import cx from 'classnames';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import appStyle from '../assets/styles/layouts/adminStyle';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import Sidebar from '../components/Sidebar/Sidebar';
import routes from '../components/Sidebar/sideBarRoutes';
import NotAllowedFB from './NoAllowedFB';
import { logout } from '../services/authService';
import identityService from '../services/identityService';
import env from '../config/environment/index';
import { setNotifications } from '../redux/notifications/index';

const logo = 'https://inyarwandamusicdev.firebaseapp.com/assets/img/logo.png';

const SimpleLayout = (props) => {
  const { classes, MainContent, history, location, match, ...rest } = props;
  const { photo, displayName, email, uid, claims } =
    JSON.parse(localStorage.getItem('au')) || {};
  const [state, setState] = useState({
    mobileOpen: false,
    miniActive: false,
    color: 'blue',
    bgColor: 'black',
    hasImage: true,
    fixedClasses: 'dropdown',
    loading: false,
  });

  const dispatch = useDispatch();
  const mainPanel =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: state.miniActive,
      [classes.mainPanelWithPerfectScrollbar]: false,
    });

  const handleDrawerToggle = () => {
    setState({
      ...state,
      mobileOpen: !state.mobileOpen,
    });
  };

  const sidebarMinimize = () => {
    setState({ ...state, miniActive: !state.miniActive });
  };

  const handleLogout = async () => {
    const resp = await identityService({
      path: '/auth/logout',
      method: 'GET',
    });
    if (resp.error) {
      Toast({
        message: response.message,
        type: 'error',
      });
    } else {
      const response = await logout();
      if (response.error) {
        Toast({
          message: response.message,
          type: 'error',
        });
      } else {
        localStorage.removeItem('au');
        window.location.assign(
          `${env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}`
        );
      }
    }
  };

  const notificationState = useSelector((store) => store.notificationsState);
  const onNotificationClick = (notification) => {
    const currentNotifs = notificationState.notifications.filter(
      (notif) =>JSON.stringify(notif) !== JSON.stringify(notification)
    );
    const redirect = (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(setNotifications(currentNotifs));
        resolve();
      });
    dispatch(setNotifications(currentNotifs));
    if (notification.click_action) {
      const url = new URL(notification.click_action);
      if (url.hostname === window.location.hostname) {
        redirect(dispatch).then(() => {
          history.push(url.pathname);
        });
      } else {
        redirect(dispatch).then(() => {
          window.location.assign(notification.click_action);
        });
      }
    }
  };

  const userProfile = {
    image: photo,
    name: displayName,
    email: email,
    claims: claims || {},
  };

  const isAllowed = true;

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={'Creative Tim'}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={state.mobileOpen}
        color={state.color}
        bgColor={state.bgColor}
        miniActive={state.miniActive}
        userProfile={userProfile}
        logout={handleLogout}
        {...rest}
      />
      <div className={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize}
          miniActive={state.miniActive}
          handleDrawerToggle={handleDrawerToggle}
          userProfile={userProfile}
          logout={handleLogout}
          notifications={notificationState.notifications}
          onNotificationClick={onNotificationClick}
          {...rest}
        />

        <main className={classes.content}>
          {state.loading ? (
            <Grid container justify='center' alignItems='center'>
              <CircularProgress variant='indeterminate' />
            </Grid>
          ) : isAllowed ? (
            props.children
          ) : (
            <NotAllowedFB />
          )}
          <Toolbar className={classes.footerToolbar} />
        </main>
      </div>
    </div>
  );
};

export default withStyles(appStyle)(SimpleLayout);
