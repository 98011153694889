import React, { useEffect, useState } from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import TablePaginationActions from "../../../components/pagination/tablePaginationActions";
import { Avatar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";
import moment from "moment";
import NoContent from "../../../components/NoContent";
import EnhancedTableHead from "../../../components/Table/tableHead";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchComponent from "../../../components/searchBar";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    padding: 10,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const createData = (name, photo, votes, id) => ({
  name,
  photo,
  votes,
  id,
});

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  doNotShowInMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  titleSkeleton: {
    width: 400,
    height: 40,
    float: "left",
    [theme.breakpoints.down("xs")]: {
      width: 100,
    },
  },
  skeleton: {
    width: "100%",
    height: 40,
    float: "right",
  },
  paginationSkeleton: {
    width: "100%",
    height: 30,
    marginLeft: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  isClickable: {
    cursor: "pointer",
  },
}));

export default (props) => {
  const { data, isUpdating, headCells, noSearch } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isInMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [page, setPage] = useState(0);
  const [displayResult, setDisplayResult] = useState({
    startIndex: 0,
    endIndex: 10,
    result: [],
    searchTerm: "",
    count: 0,
  });
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("postDate");

  const rows = data.map((elt) =>
    createData(elt.name, elt.photo, elt.votes, elt.id)
  );

  useEffect(() => {
    setDisplayResult({
      ...displayResult,
    });
  }, [isUpdating]);

  useEffect(() => {
    const contestantsData = stableSort(rows, getComparator(order, orderBy));

    setDisplayResult({
      ...displayResult,
      result: contestantsData.slice(
        displayResult.startIndex,
        displayResult.endIndex
      ),
      count: contestantsData.length,
    });
  }, [displayResult.startIndex, rows.length, orderBy, order]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setDisplayResult((prevState) => ({
      ...prevState,
      startIndex:
        page < newPage ? prevState.startIndex + 10 : prevState.startIndex - 10,
      endIndex:
        page < newPage ? prevState.endIndex + 10 : prevState.endIndex - 10,
    }));

    setPage(newPage);
  };

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-label="customized table"
          size={isInMobile ? "small" : "medium"}
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />

          <TableBody>
            {displayResult.result[0] ? (
              displayResult.result.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {isUpdating ? (
                      <Skeleton
                        variant="rect"
                        className={classes.titleSkeleton}
                      />
                    ) : (
                      <Grid container justify="flex-start" spacing={2}>
                        <Grid item>
                          <Avatar
                            alt={row.name || "Article"}
                            src={row.photo}
                            variant="rounded"
                            className={classes.doNotShowInMobile}
                          />
                        </Grid>

                        <Grid item className={classes.titleText}>
                          <span>{row.name}</span>
                        </Grid>
                      </Grid>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {isUpdating ? (
                      <Skeleton variant="rect" className={classes.skeleton} />
                    ) : (
                      `${row.votes}`
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="right"></StyledTableCell>

                <StyledTableCell align="right">
                  <NoContent name="No Content Found" />
                </StyledTableCell>

                <StyledTableCell align="right"></StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              {isUpdating ? (
                <Skeleton
                  variant="text"
                  className={classes.paginationSkeleton}
                />
              ) : (
                <TablePagination
                  colSpan={2}
                  count={displayResult.count}
                  rowsPerPage={10}
                  rowsPerPageOptions={[]}
                  page={page}
                  onChangePage={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
