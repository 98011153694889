import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from './Buttons/CustomButton';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    padding: '10vh 0',
    textAlign: 'center',
    width: '100%',
  },
  content: {
    display: 'inline-block',
    opacity: 0.7,
  },
  buttonContainer: {
    textAlign: 'center',
    width: '100%',
  },

  backTo: {
    height: '45px',
    textAlign: 'center',
    textTransform: 'none',
  },
  links: {
    textDecoration: 'none',
  },
});

export default (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant='h6' align='center'>
          {props.title || 'No content Found'}
        </Typography>
      </div>
      <br />
      {props.path && (
        <Link to={props.path} className={classes.links}>
          <Button className={classes.backTo} color='primary'>
            {props.isIndividual ? (
              <Typography variant='subtitle1'>{props.pathName}</Typography>
            ) : (
              <Typography variant='subtitle1'>
                Back to {props.pathName}
              </Typography>
            )}
          </Button>
        </Link>
      )}
    </div>
  );
};
