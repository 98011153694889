import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import env from "./environment/index";

firebase.initializeApp(env.FIREBASE_CONFIG);
const auth = firebase.auth();
// const messaging = firebase.messaging();
let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export { auth, messaging };
