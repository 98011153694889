import React, { useEffect, useState } from 'react';
import SingleOrgComponent from '../../components/accounts/SingleOrganization';
import { useDispatch, useSelector } from 'react-redux';
import { accountAction } from '../../../redux/accounts/index';
import {
  GET_USER_ORGANIZATION,
  GET_APP_INFO,
  GET_ORG_PENDING_INVITES,
} from '../../../redux/accounts/types';
import env from '../../../config/environment/index';
import identityService from '../../../services/identityService';
import Toast from '../../../components/MessageToast';

export default ({ orgId }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const addUserToOrg = async (email, role) => {
    const response = await identityService({
      method: 'POST',
      path: '/api/users/invite',
      data: {
        claims: {
          organization: {
            orgId,
            role,
          },
        },
        invfor: 'org',
        email,
        appDomain: `${window.location.protocol}//${window.location.host}`,
      },
    });

    if (response.error) {
      Toast({
        message: response.message,
        type: 'error',
        duration: 5000,
      });
    } else {
      Toast({
        message: response.message,
        type: 'success',
        duration: 5000,
      });
    }
  };
  useEffect(() => {
    dispatch(
      accountAction({
        method: 'GET',
        path: `/api/users/orgs/${orgId}`,
        actionType: GET_USER_ORGANIZATION,
      })
    );
    dispatch(
      accountAction({
        method: 'GET',
        path: `/api/apps/${env.APP_ID}`,
        actionType: GET_APP_INFO,
      })
    );
    dispatch(
      accountAction({
        method: 'GET',
        path: `/api/orgs/${orgId}/invites`,
        actionType: GET_ORG_PENDING_INVITES,
      })
    );
  }, []);
  const org = useSelector((store) => store.userOrg);
  const app = useSelector((store) => store.specificApp);
  const orgInvites = useSelector((store) => store.orgPendingInvites);
  return (
    <SingleOrgComponent
      org={org.data.results || {}}
      app={app.data.results || {}}
      onAddUser={addUserToOrg}
      apiCall={state.apiCall}
      orgInvites={orgInvites.data.results || []}
    />
  );
};
