import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReport } from '../../../redux/dashboard';
import ReportComponent from '../../components/report/ReportComponent';
import Loading from '../../../components/isLoading';
import formatArticles from '../../../helpers/formatArticles';
import TimePicker from '../../components/date/TimePickerComponent';
import moment from 'moment';
import BreadCrumps from '../../../components/BreadCrups';

const formatAuthors = (elt) => ({
  id: Math.round(Math.random() * 100000),
  name: elt.name || '',
  picture:
    elt.picture && elt.picture === ''
      ? ''
      : `https://inyarwanda.com/app/webroot/img/${elt.picture}`,
  email: elt.email || '',
  phone: elt.phone || '',
  username: elt.username || '',
  articles: elt.articles,
  earningFromLandingPages: elt.earningFromLandingPages,
  earningFromTodayPosts: elt.earningFromTodayPosts,
  earnings: elt.earnings,
  views: elt.views,
  viewsFromTodayPosts: elt.viewsFromTodayPosts,
});

export default (props) => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.reportData);

  const userString = localStorage.getItem('au');
  const user = JSON.parse(userString);
  const userInfo = {
    email: user.email,
    role: user.claims.userOrg.role,
  };
  const crumps = [
    {
      title: 'Report',
      active: true,
    },
  ];

  const [state, setState] = useState({
    reportComponentData: [],
    tabIdentifier: 'mostViewedArticles',
    isAuthors: false,
    date: moment().format('YYYY-MM-DD'),
  });

  const [dateState, setDateState] = useState({
    date: moment().format('YYYY-MM-DD'),
  });

  let startDate = moment(
    dateState.date && dateState.date.startDate
      ? dateState.date.startDate
      : dateState.date
  ).format('YYYY-MM-DD');
  let endDate = moment(
    dateState.date && dateState.date.endDate
      ? dateState.date.endDate
      : dateState.date
  ).format('YYYY-MM-DD');

  if (dateState.today) {
    startDate = moment().format('YYYY-MM-DD');
    endDate = moment().format('YYYY-MM-DD');
  } else if (dateState.daily) {
    startDate = moment(dateState.date).subtract(1, 'd').format('YYYY-MM-DD');
    endDate = moment(dateState.date).subtract(1, 'd').format('YYYY-MM-DD');
  } else if (dateState.weekly) {
    startDate = moment(dateState.date).subtract(7, 'd').format('YYYY-MM-DD');
  } else if (dateState.monthly) {
    startDate = moment(dateState.date).subtract(1, 'M').format('YYYY-MM-DD');
  } else if (dateState.yearly) {
    startDate = moment(dateState.date).subtract(1, 'y').format('YYYY-MM-DD');
  }

  useEffect(() => {
    dispatch(fetchReport(startDate, endDate));
  }, [dateState]);

  const onRangeChange = (range) => setDateState({ [range]: !state[range] });
  const onDateChange = (date) => setDateState({ ...date });

  let reportComponentData = [];

  useEffect(() => {
    if (reportData.data[state.tabIdentifier]) {
      for (const article of reportData.data[state.tabIdentifier]) {
        reportComponentData.push(formatArticles(article, userInfo));
      }
      setState({
        ...state,
        reportComponentData,
      });
    }
  }, [reportData.data, state.tabIdentifier]);

  const changeData = (identifier) => {
    let reportComponentData = [];

    if (identifier.toLowerCase().includes('author')) {
      for (const author of reportData.data[identifier]) {
        reportComponentData.push(formatAuthors(author));
      }
    } else {
      for (const article of reportData.data[identifier]) {
        reportComponentData.push(formatArticles(article, userInfo));
      }
    }

    setState({
      ...state,
      reportComponentData,
      isAuthors: identifier.toLowerCase().includes('author'),
    });
  };

  return reportData.loading ? (
    <Loading />
  ) : (
    <>
      {crumps && <BreadCrumps crumps={crumps} />}

      <TimePicker
        startDate={startDate}
        endDate={endDate}
        currentDate={moment().format('YYYY-MM-DD')}
        onRangeChange={onRangeChange}
        onDateChange={onDateChange}
        lastSyncTime={
          moment(reportData.data.lastSyncTime).format('LLL') || '00:00 AM'
        }
      />
      <ReportComponent
        content={state.reportComponentData}
        isAuthors={state.isAuthors}
        changeData={changeData}
        userInfo={userInfo}
      />
    </>
  );
};
