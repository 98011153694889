import {
  fetchDashboardData,
  fetchContestantsDataSummary,
  fetchReport,
} from "./actions/fetchActions";
import {
  dashboardDataReducer,
  contestantsDataSummaryReducer,
  reportDataReducer,
} from "./reducers/fetchReducers";

const dashReducers = {
  dashboardData: dashboardDataReducer,
  contestantsDataSummary: contestantsDataSummaryReducer,
  reportData: reportDataReducer,
};

export {
  fetchDashboardData,
  dashReducers,
  fetchContestantsDataSummary,
  fetchReport,
};
