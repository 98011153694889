import React from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    float: "right",
  },
}));

export default (props) => {
  const classes = useStyles();
  const { menuData, showChosenData } = props;

  const options = menuData.data.labels.map((elt, index) => ({
    label: elt,
    value: index + 1,
    data: {
      votes: menuData.data.datasets[0].data[index],
    },
  }));

  return (
    <div className={classes.root}>
      <ReactMultiSelectCheckboxes
        options={options}
        rightAligned
        onChange={(target) => showChosenData(target)}
        placeholder=""
      />
    </div>
  );
};
