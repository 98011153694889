import React, { Component } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import DashboardContainer  from '../containers/dashboard/index';

export class Dashboard extends Component {
  render() {
    return <DashboardLayout> 
              <DashboardContainer />
           </DashboardLayout>;
  }
}

export default Dashboard;
