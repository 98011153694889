import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  avatar: {
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  details: {
    marginLeft: 0,
    marginTop: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 10,
      marginTop: 20,
    },
  },
  telephone: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

export default (props) => {
  const classes = useStyles();
  const { item = {} } = props;

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          alt={item.name || "Contestant"}
          src={item.picture || ""}
          className={classes.avatar}
        />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={item.name}
        className={classes.details}
        secondary={
          <React.Fragment>
            <>
              <br />
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Votes: {item.votes}
              </Typography>
              <br />
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
              >
                Revenues: {`${item.revenue} Rwf`}
              </Typography>
            </>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};
