export default {
  IDENTITY_UI: "https://identity.noneho.com",
  IDENTITY_API: "https://identityapi.noneho.com",
  COMMUNICATION_API:
    "https://us-central1-nonehoidentity.cloudfunctions.net/communication",
  API_BASE_URL: "https://sspapi.noneho.com/api",
  googleAnalytics:
    "https://dev-google-analytics-dot-nonehoidentity.uc.r.appspot.com",
  APP_ID: "votingsystem1634123613600",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyDjnP77RdxOkQK2tQejPPWsah_hh32SWbk",
    authDomain: "identity.noneho.com",
    projectId: "nonehoidentity",
    storageBucket: "nonehoidentity.appspot.com",
    messagingSenderId: "868232874049",
    appId: "1:868232874049:web:e1a94d7aed289eb6db5ec0",
    measurementId: "G-G9CLN4VTS3",
  },
};
