import React from 'react';
import AccountContainer from '../../containers/accounts/UserAcountContainer';
import AppLayout from '../../../layouts/DashboardLayout';
import OrganizationContainer from '../../containers/accounts/OrganizationContainer';

export default (props) => {
  const id = props.match.params.orgId;
  if (id) {
    return (
      <AppLayout>
        <OrganizationContainer orgId={id} />
      </AppLayout>
    );
  }
  return (
    <AppLayout>
      <AccountContainer />
    </AppLayout>
  );
};
