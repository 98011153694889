import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import NoContent from "../../../components/NoContent";
import BreadCrumps from "../../../components/BreadCrups";
import UserComponent from "../../../components/userComponent";
import SearchComponent from "../../../components/searchBar";
import { primaryColor } from "../../../assets/styles/base";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },

  link: {
    textDecoration: "none",
    color: "inherit",
  },
  paginationParent: {
    textAlign: "center",
    padding: 10,
  },
  pagination: {
    display: "inline-block",
  },
  "&.MuiPagination-ul": {
    color: primaryColor[0],
  },
}));

export default (props) => {
  const classes = useStyles();
  const {
    content,
    page,
    crumps,
    onPaginationChange,
    count,
    searchValue,
    onSearching,
  } = props;

  const onPageChange = (event, page) => onPaginationChange(event, page);

  const onSearch = (term) => onSearching(term);

  return (
    <div>
      {crumps && <BreadCrumps crumps={crumps} />}

      <List className={classes.root}>
        {onSearching && (
          <SearchComponent
            value={searchValue}
            placeholder="Search Author"
            onChange={onSearch}
          />
        )}
        {content.length > 0 ? (
          content.map((item, index) => (
            // <Link
            //   to={`/contestants/${item.id}`}
            //   className={classes.link}
            //   key={item.id}
            // >
            <div className={classes.link} key={item.id}>
              <UserComponent item={item} />
              {index === content.length - 1 ? null : (
                <Divider variant="inset" component="li" />
              )}
            </div>
            // </Link>
          ))
        ) : (
          <NoContent title="No Content" />
        )}

        {content.length > 0 && page && (
          <div className={classes.paginationParent}>
            <Pagination
              count={Math.round(count / 25) + 1}
              hidePrevButton={page === 1}
              hideNextButton={page === Math.round(count / 25) + 1}
              page={page}
              onChange={(event, page) => onPageChange(event, page)}
              className={classes.pagination}
            />
          </div>
        )}
      </List>
    </div>
  );
};
