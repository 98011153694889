import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
    primaryColor,
    whiteColor,
} from "../assets/styles/base";
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icon from '@material-ui/core/Icon';
import SelectComponent from './CustomSelect/SelectCheckbox';


const useStyles = makeStyles((theme) => ({
    root: {
      float:"right", 
      padding:10 
    },
    button: {
         background: primaryColor[0], 
         color: whiteColor,
         marginRight:10,
    },
}));

export default  (props)=> {
  const classes = useStyles();
  
  const {
      onMenuClose,
      menuList,
      menuTitle,
      data,
      showChosenData
  } = props;
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
     if (event.target.dataset.range){
        onMenuClose(event.target.dataset.range);
     };
  };
  
  return (
    <div className={classes.root}>
      <Button 
          aria-controls="simple-menu" 
          aria-haspopup="true" 
          onClick={handleClick}
          className={classes.button}
      >
        { menuTitle|| 'Open Menu' } 
        <Icon>
          <ExpandMoreIcon />
        </Icon>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      {
        menuList[0]?
            menuList.map((item, index)=>(
              <MenuItem 
                  key={index} 
                  data-range={item.toLowerCase()} 
                  onClick={handleClose}
              >
                  {item}
              </MenuItem>  
            ))
            :null
      }
      </Menu>
      <SelectComponent menuData={data} showChosenData={showChosenData} />
                    
    </div>
  );
}
