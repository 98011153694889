import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import env from '../config/environment/index';
import identityService from '../services/identityService';
import { auth } from '../config/firebase';
import { loginWithCustomToken } from '../services/authService';
import Loading from '../components/isLoading';
import FixedAlert from '../components/alert';

export default class AuthProvider extends Component {
  state = {
    loading: true,
  };

  handleAuthCheck = async () => {
    const resp = await identityService({
      path: '/auth/status',
      method: 'GET',
    });
    if (resp.error && resp.status === 401) {
      window.location.assign(
        `${env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}${window.location.pathname}`
      );
    } else if (resp.error) {
      this.setState({ setupComplete: true, loading: false });
    } else {
      const currentUser = JSON.parse(localStorage.getItem('au'));
      if (
        !currentUser ||
        (resp.results && currentUser.uid !== resp.results.uid)
      ) {
        this.setState({ firstTime: true });
        const user = await loginWithCustomToken(
          resp.results && resp.results.customToken
        );
        if (user.error) {
          this.setState({
            error: user.message,
            loading: false,
            setupComplete: true,
          });
        } else this.setState({ setupComplete: true });
      } else {
        this.setState({ setupComplete: true });
      }
    }
  };

  componentDidMount() {
    if (!localStorage.getItem('autoReload')) {
      this.handleAuthCheck();
    } else {
      localStorage.removeItem('autoReload');
    }
    this.onAuthChange = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser && currentUser.emailVerified) {
        try {
          const userData = await currentUser.getIdTokenResult(true);
          const userApp =
            (userData.claims.apps &&
              userData.claims.apps.find((app) => app.appId === env.APP_ID)) ||
            {};
          const userOrg =
            (userData.claims.organizations &&
              userData.claims.organizations.find(
                (org) => org.appId === env.APP_ID
              )) ||
            {};
          localStorage.setItem(
            'au',
            JSON.stringify({
              displayName: currentUser.displayName,
              email: currentUser.email,
              photo: currentUser.photoURL,
              uid: currentUser.uid,
              phoneNumber: currentUser.phoneNumber,
              claims: {
                userApp,
                userOrg,
              },
              atkn: userData.token,
            })
          );
          if (this.state.firstTime) {
            localStorage.setItem('autoReload', 'on first time');
            window.location.reload();
          } else this.setState({ loading: false });
        } catch (error) {
          this.setState({ loading: false });
        }
      } else {
        if (this.state.setupComplete) {
          localStorage.removeItem('au');
          window.location.assign(
            `${env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}${window.location.pathname}`
          );
        } else {
          this.setState({
            loading: !this.state.setupComplete,
            error:
              currentUser && !currentUser.emailVerified
                ? 'Your account is not verified'
                : this.state.setupComplete &&
                  'Authentication failed, retry or contact administrator',
          });
        }
      }
    });

    setInterval(async () => {
      const token = await auth.currentUser.getIdToken(true);
      const currentUser = JSON.parse(localStorage.getItem('au'));
      currentUser.atkn = token;
      localStorage.setItem('au', JSON.stringify(currentUser));
    }, 56 * 60 * 1000);
  }

  componentWillUnmount() {
    this.onAuthChange();
  }
  render() {
    return (
      <div>
        {this.state.loading ? (
          <Box padding={2}>
            <Grid
              container
              justify='center'
              alignItems='center'
              direction='column'
            >
              <Loading />
              <Typography align='center'>Please wait a moment...</Typography>
            </Grid>
          </Box>
        ) : this.state.error && this.state.setupComplete ? (
          <Box padding={2}>
            <Grid
              container
              justify='center'
              alignItems='center'
              direction='column'
            >
              <FixedAlert title={this.state.error} type='warning' />
            </Grid>
          </Box>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}
