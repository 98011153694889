import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { darkGrey, primaryColor } from '../assets/styles/base';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
  },
  links: {
    color: darkGrey,
    '&:hover': {
      cursor: 'pointer',
      color: primaryColor[0],
    },
  },
  active: {
    color: primaryColor[0],
  },
}));

export default (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Breadcrumbs separator='›' aria-label='breadcrumb'>
        <Link to='/' className={classes.links}>
          Noneho
        </Link>
        {props.crumps.map((crump, index) => {
          if (crump.active)
            return (
              <Typography className={classes.active} key={index}>
                {crump.title}
              </Typography>
            );
          return (
            <Link to={crump.path} className={classes.links} key={index}>
              {crump.title}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};
