import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
// import reducers
import { dashReducers } from "./dashboard/index";
import { accountReducers } from "./accounts/index";
import { notificationsReducers } from "./notifications";
import { uploadReducers } from "./upload";
import { searchReducers } from "./search";

const reducers = combineReducers({
  ...dashReducers,
  ...accountReducers,
  ...notificationsReducers,
  ...uploadReducers,
  ...searchReducers,
});

export default () => {
  const store = createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(thunk, promise))
  );

  return store;
};
