import orgsReducers from './reducers/orgsReducers';
import appsReducers from './reducers/appsReducers';
import accountAction from './actionCreator';

const accountReducers = {
  userOrgs: orgsReducers.userOrganizations,
  userOrg: orgsReducers.userOrganization,
  specificApp: appsReducers.getAppReducer,
  orgPendingInvites: orgsReducers.orgPendingInvites,
};

export { accountAction, accountReducers };
