export default (article, userInfo = {}) => ({
  title: article.title,
  thumbNail:
    article.wallPhoto === ""
      ? ""
      : `https://inyarwanda.com/app/webroot/img/${article.wallPhoto}`,
  url: `https://inyarwanda.com${article.url}`,
  views: article.views,
  revenue: article.earnings || 0,
  noRevenue:
    userInfo.role !== "owner" &&
    userInfo.role !== "admin" &&
    userInfo.email !== article.authorEmail,
  author: {
    id: article.authorId || Math.round(Math.random() * 100),
    name: article.authorName || "No name so far",
    email: article.authorEmail,
  },
  postDate: article.postDate,
  id: article.pagePathLevel2
    ? parseInt(article.pagePathLevel2.replace(/\//, ""))
    : Math.round(Math.random() * 100000),
  secondaryTitle: article.url,
});

export const folderDate = new Date()
  .toISOString()
  .replace("-", "")
  .substring(0, 6);
