import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "../views/pages/Dashboard";
import AccountPage from "../views/pages/accounts/AccountPage";
import ReportPage from "../views/pages/Report";
import componentAllowed from "../helpers/authorization/componentAllowed";
import ContestantsPage from "../views/pages/Contestants";

const ProtectedRoute = ({
  component: Component,
  roles,
  accessLevel,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        componentAllowed(roles, accessLevel) ? (
          <Component {...props} />
        ) : (
          <h6>Access denied</h6>
        )
      }
    />
  );
};

const AppRoute = () => {
  return (
    <BrowserRouter>
      <div className="app">
        <Switch>
          <ProtectedRoute
            roles={["owner", "admin", "editor", "chiefEditor"]}
            exact
            path="/"
            component={Dashboard}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "editor", "chiefEditor"]}
            exact
            path="/account"
            component={AccountPage}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin", "editor", "chiefEditor"]}
            exact
            path="/account/orgs/:orgId"
            component={AccountPage}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin", "editor", "chiefEditor"]}
            exact
            path="/report"
            component={ReportPage}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin", "editor", "chiefEditor"]}
            exact
            path="/contestants"
            component={ContestantsPage}
            accessLevel="org"
          />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default AppRoute;
