import React, { Component } from "react";
import DataTableComponent from "../../components/data/DataTableComponent";
export class ArticlesTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { contestantsData, isUpdating, role, noSearch } = this.props;

    const headCells = [
      { id: "name", rightAlign: false, label: "Name" },
      { id: "Votes", rightAlign: true, label: "Votes" },
    ];

    return (
      <DataTableComponent
        noSearch={noSearch}
        role={role}
        isUpdating={isUpdating}
        data={contestantsData}
        headCells={headCells}
      />
    );
  }
}

export default ArticlesTableContainer;
