import React, { Component } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import ContestantContainer from "../containers/contestants/ContestantsContainer";

export class ContestantsPage extends Component {
  render() {
    return (
      <DashboardLayout>
        <ContestantContainer />
      </DashboardLayout>
    );
  }
}

export default ContestantsPage;
