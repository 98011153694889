// @material-ui/icons
import PeopleIcon from "@material-ui/icons/SupervisorAccount";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountsIcon from "@material-ui/icons/AccountCircle";

var dashRoutes = [
  {
    path: "/",
    name: "Dashboard",
    icon: DashboardIcon,
    page: "dashboard",
    roles: ["admin", "editor", "chiefEditor", "owner"],
  },
  {
    path: "/contestants",
    name: "Contestants",
    icon: PeopleIcon,
    page: "contestants",
    roles: ["admin", "editor", "chiefEditor", "owner"],
  },
  // {
  //   path: "/account",
  //   name: "My Account",
  //   page: "account",
  //   mini: "AC",
  //   permission: "read",
  //   icon: AccountsIcon,
  //   roles: ["admin", "owner"],
  // },
];

export default dashRoutes;
