import {
  FETCH_GROUPED_CONTESTANTS,
  FETCH_GROUPED_CONTESTANTS_SUMMARY,
  FETCH_REPORT,
} from "../types";
import fetchService from "../../../services/fetchService";
import env from "../../../config/environment/index";

const baseUrl = {
  supplyApi: env.API_BASE_URL,
  googleAnalytics: env.googleAnalytics,
};

export const fetchDashboardData =
  (user, startDate, endDate) => async (dispatch) =>
    await dispatch({
      type: FETCH_GROUPED_CONTESTANTS,
      message: `All Authors' grouped data`,
      payload: fetchService(
        `${baseUrl.supplyApi}/admin/summary/articles?startDate=${startDate}&endDate=${endDate}`
      ),
    });

export const fetchContestantsDataSummary =
  (startDate, endDate) => async (dispatch) =>
    await dispatch({
      type: FETCH_GROUPED_CONTESTANTS_SUMMARY,
      message: `summary data`,
      payload: fetchService(
        `https://us-central1-nonehoidentity.cloudfunctions.net/votingApi/api/votings/rwandainfluencerawards2021/stats?startDate=${startDate}&endDate=${endDate}`
      ),
    });

export const fetchReport = (startDate, endDate) => async (dispatch) =>
  await dispatch({
    type: FETCH_REPORT,
    message: `Report Data`,
    payload: fetchService(
      `${baseUrl.supplyApi}/admin/report?startDate=${startDate}&endDate=${endDate}`
    ),
  });
