import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Hidden from '@material-ui/core/Hidden';
import Tab from '@material-ui/core/Tab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import BusinessIcon from '@material-ui/icons/Business';
import Edit from '@material-ui/icons/Edit';
import CustomCard from '../../../components/card/CustomCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubHeader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import UserOrganizations from './UserOrganizations';
import SingleOrganization from './SingleOrganization';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mobTabs: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  deskTabs: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingTop={2}>{children}</Box>}
    </div>
  );
}

export default ({
  org,
  user,
  loading,
  onOrgCreate,
  app,
  onAddUser,
  orgInvites,
  onRemoveUser,
  onChangeUserRole,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box>
      <Typography variant='h5'>Business</Typography>
      <SingleOrganization
        app={app}
        onAddUser={onAddUser}
        org={org}
        orgInvites={orgInvites}
        onRemoveUser={onRemoveUser}
        onChangeUserRole={onChangeUserRole}
      />
    </Box>
  );
};
