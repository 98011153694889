export const tabsData = [{
     categoryName: 'most Viewed Articles',
     identifier: 'mostViewedArticles',
 }, {
     categoryName: 'least Viewed Articles',
     identifier: 'leastViewedArticles',
 },{
     categoryName: 'most Viewed AUthors',
     identifier: 'mostViewedAUthors',
 }, {
     categoryName: 'least Viewed AUthors',
     identifier: 'leastViewedAUthors',
 },
 
 
 ]