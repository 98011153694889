import React, { Component } from "react";
import RecordsComponent from "../../components/dashboard/RecordsComponent";

export class RecordsContainer extends Component {
  render() {
    const { isUpdating, role, recordsToDisplay } = this.props;

    const records = {
      votes: recordsToDisplay.TotalVotes,
      contestants: recordsToDisplay.NumberOfCurrentCandidates,
      totalRevenues: recordsToDisplay.TotalRevenue,
    };

    return (
      <>
        <RecordsComponent
          role={role}
          records={records}
          isUpdating={isUpdating}
        />
      </>
    );
  }
}

export default RecordsContainer;
