import { GET_APP_INFO, GET_ALL_APPS } from '../types';

const initialState = {
  loading: false,
  data: {},
};

export default class Organizations {
  static getAllAppsReducer(state = initialState, action) {
    switch (action.type) {
      case `${GET_ALL_APPS}_PENDING`:
        return { ...state, loading: true };
      case `${GET_ALL_APPS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_ALL_APPS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static getAppReducer(state = initialState, action) {
    switch (action.type) {
      case `${GET_APP_INFO}_PENDING`:
        return { ...state, loading: true };
      case `${GET_APP_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_APP_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
