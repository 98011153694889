import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import itemInfoStyles from "../../../assets/styles/components/itemInfoStyles";
import TabsComponent from "../../../components/Tabs/TabsComponent";
import TabPanel from "../../../components/Tabs/TabPanel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { tabsData } from "../../../helpers/dumyData/tabsData";
import UsersListComponent from "../List/UsersListComponent";
import ArticlesTableContainer from "../../containers/dashboard/ContestantsTableContainer";

const styles = makeStyles((theme) => itemInfoStyles(theme));

const a11yProps = (index, identifier) => ({
  id: `scrollable-auto-tab-${index}`,
  "aria-controls": `scrollable-auto-tabpanel-${index}`,
});

export default (props) => {
  const { content, changeData, isAuthors, userInfo } = props;

  const [value, setValue] = useState(0);

  const classes = styles();

  const handleChangeIndex = (event, newValue) => {
    setValue(newValue);
    changeData(tabsData[newValue].identifier);
  };

  return (
    <div className={classes.root}>
      <div>
        <TabsComponent
          handleChangeIndex={handleChangeIndex}
          tabs={
            <Tabs
              // centered
              value={value}
              onChange={handleChangeIndex}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {tabsData.map((tabElt, index) => (
                <Tab
                  label={tabElt.categoryName}
                  key={index}
                  {...a11yProps(index, tabElt.identifier)}
                />
              ))}
            </Tabs>
          }
        >
          {tabsData.map((tabElt, index) => (
            <TabPanel key={index + 1} value={value} index={index}>
              {isAuthors ? (
                <UsersListComponent content={content} />
              ) : (
                <ArticlesTableContainer
                  noSearch
                  role={userInfo.role}
                  isUpdating={false}
                  contestantsData={content}
                />
              )}
            </TabPanel>
          ))}
        </TabsComponent>
      </div>
    </div>
  );
};
