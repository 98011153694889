export default {
  IDENTITY_UI: "http://localhost:8081",
  IDENTITY_API: "http://localhost:7000",
  COMMUNICATION_API:
    "http://localhost:5001/nonehoidentity/us-central1/communication",
  API_BASE_URL: "http://localhost:5000/api",
  googleAnalytics:
    "https://dev-google-analytics-dot-nonehoidentity.uc.r.appspot.com",
  APP_ID: "votingsystem1634123613600",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyDjnP77RdxOkQK2tQejPPWsah_hh32SWbk",
    authDomain: "identity.noneho.com",
    projectId: "nonehoidentity",
    storageBucket: "nonehoidentity.appspot.com",
    messagingSenderId: "868232874049",
    appId: "1:868232874049:web:e1a94d7aed289eb6db5ec0",
    measurementId: "G-G9CLN4VTS3",
  },
};
