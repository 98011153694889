import React, { useState, useEffect } from "react";
import Loading from "../../../components/isLoading";
import MultiRecordsChartComponent from "../../components/stats/MultiRecordsChart";
import moment from "moment";
import NoContent from "../../../components/NoContent";
import { formatChartData } from "../../../helpers/formatData";
import { whiteColor } from "../../../assets/styles/base";
import MenuComponent from "../../../components/Menu";

export default (props) => {
  const { chartData, isUpdating, role, isDateRange, isEditor } = props;

  const [state, setState] = useState({
    activeMenuName: "Contestants",
    activeMenuIdentifier: "contestants",
    activeMenuRevenue: "contestantsRevenue",
    dataToDisplay: {
      data: {},
      revenues: {},
    },
    initialData: {
      data: {},
      revenues: {},
    },
  });

  const onMenuClose = (identifier) => {
    props.changeContestantsForSelectedMenu("", []);
    setState({
      ...state,
      activeMenuName: identifier.charAt(0).toUpperCase() + identifier.slice(1),
      activeMenuIdentifier: identifier,
      activeMenuRevenue: `${identifier}Revenue`,
    });
  };

  const menuList = ["Contestants", "Dates"];

  useEffect(() => {
    if (chartData.candidatesSummary) {
      setState({
        ...state,
        initialData: {
          data: formatChartData(chartData, state.activeMenuIdentifier),
          revenues: formatChartData(chartData, state.activeMenuRevenue, role),
        },

        dataToDisplay: {
          data: formatChartData(chartData, state.activeMenuIdentifier),
          revenues: formatChartData(chartData, state.activeMenuRevenue, role),
        },
      });
    }
  }, [chartData, state.activeMenuIdentifier]);

  const showChosenData = (selectLabels) => {
    const selectLabelsArr = selectLabels.map((elt) => elt.label);
    const selectDatasetsArr = selectLabels.map((elt) => ({
      votes: elt.data.votes,
      revenues: elt.data.revenues,
    }));

    setState((prevState) => ({
      ...prevState,

      dataToDisplay: {
        data: {
          ...prevState.dataToDisplay.data,
          datasets: [
            {
              ...prevState.initialData.data.datasets[0],
              data: prevState.initialData.data.datasets[0].data
                .map((item, index) => {
                  const itemIsSelected = selectDatasetsArr.find(
                    (obj) => obj.votes === item
                  );

                  if (itemIsSelected) {
                    return item;
                  }
                })
                .filter((elt) => !!elt),
            },
          ],

          labels: prevState.initialData.data.labels
            .map((item) => {
              if (selectLabelsArr.includes(item)) {
                return item;
              }
            })
            .filter((elt) => !!elt),
        },
      },
      revenues: {
        ...prevState.dataToDisplay.revenues,
        datasets: [
          {
            ...prevState.initialData.revenues.datasets[0],
            data: prevState.initialData.revenues.datasets[0].data
              .map((item, index) => {
                const itemIsSelected = selectDatasetsArr.find(
                  (obj) => obj.revenues === item
                );

                if (itemIsSelected) {
                  return item;
                }
              })
              .filter((elt) => !!elt),
          },
        ],

        labels: prevState.initialData.revenues.labels
          .map((item) => {
            if (selectLabelsArr.includes(item)) {
              return item;
            }
          })
          .filter((elt) => !!elt),
      },
    }));

    // props.changeArticlesForSelectedMenu(
    //   state.activeMenuIdentifier,
    //   selectLabels
    // );
  };

  return isUpdating ? (
    <Loading />
  ) : (
    <>
      {state.dataToDisplay.data.labels ? (
        <>
          <MenuComponent
            onMenuClose={onMenuClose}
            menuList={menuList}
            menuTitle={state.activeMenuName}
            showChosenData={showChosenData}
            data={state.initialData}
          />

          <MultiRecordsChartComponent
            data={
              state.dataToDisplay.data.labels[0]
                ? state.dataToDisplay.data
                : state.initialData.data
            }
            background={whiteColor}
            title={state.activeMenuName}
          />
          <MultiRecordsChartComponent
            data={
              state.dataToDisplay.revenues.labels[0]
                ? state.dataToDisplay.revenues
                : state.initialData.revenues
            }
            background={whiteColor}
            title="Revenues"
          />
        </>
      ) : (
        <NoContent title={"No Content ."} />
      )}
    </>
  );
};
